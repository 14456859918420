<template>
  <b-card>
    <ValidationObserver>
      <form @submit.prevent="saveItem">
        <b-row>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="balance">Balance:</label>
              <validation-provider
                name="balance"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="balance"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.balance"
                  placeholder="0"
                />
              </validation-provider>
            </div>
            <div class="form-group">
              <div
                class="d-flex align-items-center justify-content-between mb-1"
              >
                <label for="files">Files:</label>
                <b-button
                  variant="primary"
                  class="btn-sm waves-effect waves-float waves-light btn-primary cursor-pointer"
                  :disabled="formPayload.files.length >= 3"
                  @click="addFileInput"
                >
                  Add More Files
                </b-button>
              </div>
              <div
                v-for="(file, index) in formPayload.files"
                :key="index"
                class="form-group"
              >
                <validation-provider
                  :name="'file' + index"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <b-form-file
                    :id="'image' + index"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    ref="fileInput"
                    accept="image/png, image/jpeg"
                    @change="handleFileChange($event, index)"
                  />
                </validation-provider>
                <div v-if="file" class="mt-1">
                  <img :src="file" class="w-100 product__image rounded" />
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="platformFee">Platform Fee:</label>
              <validation-provider
                name="platformFee"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="platformFee"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.platformFee"
                  placeholder="0"
                />
              </validation-provider>
            </div>
          </b-col>
        </b-row>

        <b-col cols="12" class="p-0 mt-2">
          <button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary"
            :disabled="isLoading"
          >
            {{ isLoading ? "Submitting..." : "Submit" }}
          </button>
          &nbsp; &nbsp;
          <a
            @click="handleBackButton"
            variant="secondary"
            class="btn waves-effect waves-float waves-light btn-secondary"
          >
            Cancel
          </a>
        </b-col>
      </form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import {
  BCard,
  BButton,
  BFormGroup,
  BFormRadio,
  BFormFile,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { successNotification, errorNotification } from "@/auth/utils";

configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
  },
});

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormFile,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      required,
      isLoading: false,
      formPayload: {
        balance: "",
        platformFee: "",
        files: [null],
      },
      validations: "",
    };
  },
  methods: {
    handleBackButton() {
      history.back();
    },
    addFileInput() {
      this.formPayload.files.push(null);
    },
    handleFileChange(event, index) {
      const file = event.target.files[0];
      this.$set(this.formPayload.files, index, URL.createObjectURL(file));
    },
    saveItem() {
      const params = this.$route.params.id;
      this.isLoading = true;
      const payload = new FormData();
      if (this.formPayload.balance) {
        payload.append("balance", this.formPayload.balance);
      }
      if (this.formPayload.platformFee) {
        payload.append("platformFee", this.formPayload.platformFee);
      }
      this.formPayload.files.forEach((file, index) => {
        payload.append(`file${index}`, file);
      });

      this.$http
        .put(`/admin/transaction/refund/${params}`, payload)
        .then((response) => {
          this.isLoading = false;
          successNotification(this, "Success", "Refund completed!");
          this.$router.push({ name: "transaction" });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta) {
            this.validations = error.response.data.meta.message;
            errorNotification(this, "Error", this.validations.join(", "));
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
.product__image {
  width: 100%;
  height: auto;
  object-fit: contain;
}
</style>
